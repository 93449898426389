<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-card-body class="p-0">
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Title" label-for="title">
                  <b-form-input
                    id="title"
                    v-model="dataInput.title"
                    placeholder="Title"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Upload Logo" label-for="upload_logo">
                  <b-form-file
                    ref="file-input"
                    accept="image/*"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @change="setFileImage"
                  />
                </b-form-group>
                <div v-if="dataInput.file.url" 
                  style="position: relative">
                  <b-img
                    :src="dataInput.file.url"
                    rounded
                    alt="Rounded image"
                    class="d-inline-block mr-1 mb-1 w-100"
                  />
                  <div style="position: absolute; top: 0.45rem; right: 0.5rem"
                    @click="resetImage">
                    <b-button
                      variant="gradient-danger"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
              <b-col cols="12">
                <label for="description">Description</label>
                <b-form-textarea
                  v-model="dataInput.description"
                  id="description"
                  placeholder="Description"
                  rows="3"
                />
              </b-col>
              <b-col cols="12" md="6" class="mt-1">
                <b-form-group
                  label="Date"
                  label-size="sm"
                  label-for="filterDate"
                >
                  <b-input-group>
                    <flat-pickr
                      id="filterDate"
                      v-model="dataInput.date"
                      class="form-control"
                      :config="{ mode: 'range'}"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" class="mt-1">
                <b-form-group label="Pin Code">
                  <b-input-group>
                    <b-form-input v-model="dataInput.pin_code" placeholder="Pin Code" />
                    <b-input-group-append>
                      <b-button variant="primary" @click="generateCode()">
                        Generate
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" class="mt-1">
                <b-button variant="primary" @click="submit">Submit</b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      static
      v-model="modal_crop_image"
      id="crop-image"
      ref="cropimage"
      title="Crop Gambar"
      @hidden="resetCropImage"
      @ok="handleCropImage"
    >
      <vue-cropper
        ref="cropper"
        :src="dataInput.file_raw"
        :crop-box-resizable="false"
        :toggle-drag-mode-on-dblclick="false"
      />
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-1"
        @click="setCropArea"
      >
        Set Default Crop
      </b-button>
    </b-modal>
  </div>
</template>

<script>
  import {
		BRow, BCol,
    BCard, BCardBody,
    BForm, BFormGroup, BFormInput, BFormFile, BFormTextarea, BInputGroup, BInputGroupAppend,
    BButton, BImg
	} from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import flatPickr from 'vue-flatpickr-component'
  import VueCropper from 'vue-cropperjs';
  import 'cropperjs/dist/cropper.css';

  export default {
    components: {
      BRow, BCol,
      BCard, BCardBody,
      BForm, BFormGroup, BFormInput, BFormFile, BFormTextarea, BInputGroup, BInputGroupAppend,
      BButton, BImg,
      flatPickr, VueCropper
		},
    directives: {
      Ripple,
    },
    data() {
      return {
        dataInput: {
          title: null,
          description: null,
          date: null,
          pin_code: null,
          file: {
            url: null,
            image: null
          },
          file_raw: null
        },
        modal_crop_image: false
      }
    },
		created() {
      this.checkAuth()
    },
    methods: {
      generateCode() {
        let result = Math.floor(Math.random() * (999999 - 100000)) + 100000;
        this.dataInput.pin_code = result
      },
      setFileImage(event) {
        const file = event.target.files[0]

        if (file.type.indexOf('image/') === -1) {
          this.$refs['file-input'].reset()
          this.runToast('error', 'Please select an image file')
          return;
        }

        if (typeof FileReader === 'function') {
          const reader = new FileReader();

          reader.onload = (event) => {
            let size_file = Math.floor(file.size / 1024)
            
            if(size_file <= 1024) {
              this.dataInput.file_raw = event.target.result;
          
              this.showModalCrop()

              // rebuild cropperjs with the updated source
              this.$refs.cropper.replace(event.target.result)
            } else {
              this.$refs['file-input'].reset()
              this.runToast('error', 'Ukuran file lebih dari 1 MB')
            }
          };
          reader.readAsDataURL(file)
        } else {
          this.$refs['file-input'].reset()
          this.runToast('error', 'Sorry, FileReader API not supported')
        }
      },
      resetImage() {
        this.$refs['file-input'].reset()

        this.dataInput.file.image = null
        this.dataInput.file.url = null
        this.dataInput.file_raw = null
      },

      showModalCrop() {
        this.modal_crop_image = true
      },
      setCropArea() {
        this.$refs.cropper.setCropBoxData({
          width: 512,
          height: 512
        })
      },
      resetCropImage() {
        if(!this.dataInput.file_raw) {
          this.$refs['file-input'].reset()
        }
      },
      handleCropImage() {
        this.dataInput.file.url = this.$refs.cropper.getCroppedCanvas().toDataURL()
        
        let block = this.dataInput.file.url.split(";");
        let content_type = block[0].split(":")[1]
        let real_data = block[1].split(",")[1];
        this.dataInput.file.image = this.b64toBlob(real_data, content_type)
      },
      b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
      },

      submit() {
        let cid = localStorage.getItem('client_id')
        let input = this.dataInput
        const data = new FormData()

        data.append('title', input.title)
        data.append('logo', input.file.image)
        data.append('description', input.description)

        let date = input.date
        let date_split = date.split(" to ")

        data.append('start_date', date_split[0])
        data.append('end_date', date_split[1])
        data.append('pin_code', input.pin_code)

        const config = {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        }

        this.$axios.post(`${cid}/partner`, data, config)
          .then(res => {
            // Success code
            this.$bvToast.toast('Success', {
              title: 'Add Partner',
              variant: 'success',
              solid: true,
              toaster: 'b-toaster-bottom-right',
              autoHideDelay: 3000
            })

            setTimeout(() => {
              this.$router.push({ path: '/partner' })
            }, 3000);
          })
          .catch(error => {
            console.log(error)
          })
      }
    }
  }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>